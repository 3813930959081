













import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import bgImage from './individual-meter-heat-meter-counter-lg.svg';

@Component
export default class IndividualMeterHeatMeterCounterDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private backgroundImage = bgImage;
}
